
export const isWeb = () => {
  return window.innerWidth > 991;
};

export async function fetchConfig(){
  return fetch("/config.json")
  .then(res=> res.json())
  .then( config => { return config })
  .catch(e=> console.error("Err: " + e));
  
};

export const copyToClipboard = textParentElement => {
  var el = document.createElement('textarea');
  el.value = textParentElement;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', display: 'hidden' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

/** Gönderilen ayı yazıya çevirir
 * sadece sayısal input
 */
export const MonthNameLocalizeToTR = month => {
  if (typeof month !== 'number') return null;
  switch (month) {
    case 1:
      return 'Ocak';
    case 2:
      return 'Şubat';
    case 3:
      return 'Mart';
    case 4:
      return 'Nisan';
    case 5:
      return 'Mayıs';
    case 6:
      return 'Haziran';
    case 7:
      return 'Temmuz';
    case 8:
      return 'Ağustos';
    case 9:
      return 'Eylül';
    case 10:
      return 'Ekim';
    case 11:
      return 'Kasım';
    case 12:
      return 'Aralık';
    default:
      return;
  }
};

export const fetchRadioValue = (url, callbackFunc) => {
    const formatSongName = (artistName, songName) => {
        return artistName && songName ? `${artistName} - ${songName}` : 'Radyo D Hit Müzik';
    };
    fetch(url)
        .then(response => response.json())
        .then(data => {
            const radioData = {
                programName: data.ProgramName,
                programImage: data.ProgramImage?.endsWith(".jpg") ? data.ProgramImage : data.ProgramImage + ".jpg",
                songName: formatSongName(data.ArtistName, data.SongName),
                songImage: data.SongImage?.endsWith(".jpg") ? data.SongImage : data.SongImage + ".jpg",
                nextProgramName: data.NextProgramName,
                nextProgramImage: data.NextProgramImage?.endsWith(".jpg") ? data.NextProgramImage : data.NextProgramImage + ".jpg",
                PrevArtistName: data.PrevArtistName,
                PrevSongName: data.PrevSongName,
                NextArtistName: data.NextArtistName,
                NextSongName: data.NextSongName
            }
            callbackFunc(radioData)
        })
        .catch(err => {
            console.error("Error Reading data " + err);
        });
}

export function isInternalUrl (url) {
  if (typeof url == 'string' && url[0] === '/') return true
  else return false
}

export function calculateDurationDisplay(duration) {
  duration = Math.floor(duration)
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor(duration / 60) % 60
  const seconds = duration % 60

  let response = ''

  if(hours > 0) 
    response += hours < 10 ? ('0' + hours + ':') : hours + ':'

    response += minutes < 10 ? ('0' + minutes + ':') : minutes + ':'

    response += seconds < 10 ? ('0' + seconds) : seconds

    return response
}